import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { CookieService } from '../services/cookie.service';
import { environment } from '../../environments/environment';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  isLogin = false;
  roleAs: string;

  private user: User;
  private currentUser: ReplaySubject<User>;
  private authToken: string;
  private sessionKey = 'loginData';

  mobile: any;
  lStatus: any;

  userdata: string;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    this.currentUser = new ReplaySubject<User>(1);
    this.getUser().subscribe((user) => {
      this.user = user;
    })
    let loginData;
    if (sessionStorage.getItem('loginData')) {
      loginData = JSON.parse(sessionStorage.getItem('loginData'));
      if (loginData.user)
        this.setCurrentUser(loginData.user);
    }

    this.authToken = sessionStorage.getItem('authToken');
  }

  User: any;
  Usertype: any;

  post(url: string, body: any, options?: any) {
    return this.http
      .post(url, body, options)
      // .pipe(map(this.extractData))
      .toPromise()
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    if (error.status == 400) {
      // this.toastServ.danger(error["error"].message, 'Error')
      return error
    }
    else {
      return 'Server error';
    }
  }

  public getUser(): Observable<User> {
    return this.currentUser.asObservable();
  }

  public setCurrentUser(user: User) {
    if (typeof user === 'object') {
      user = new User(user);
      if (user.isBlocked === false && user.userId) {
        this.setSession(user);
        // if (user.connected)
        //   this.flairServ.connect();
        this.currentUser.next(user);
        return true;
      }
    }
    return false;
  }

  private setSession(userData) {
    let timestamp = new Date().getTime();
    sessionStorage.setItem(this.sessionKey, JSON.stringify({ user: userData }));
  }

  login(value: string) {
    this.isLogin = true;
    this.roleAs = value;
    localStorage.setItem('STATE', 'true');
    localStorage.setItem('ROLE', this.roleAs);
  }

  register(body: any, options?: any) {
    return this.post(environment.baseURL + 'api/auth/register/JOB PROVIDER', body, options)
      .then((response: any) => {
        return response;
      })
      .catch(this.handleError);
  }


  sendOTP(body: any, options?: any) {
    return this.post(environment.baseURL + 'api/auth/sendOTP', body, options)
      .then((response: any) => {
        return response;
      })
      .catch(this.handleError);
  }

  verifyOTP(body: any, options?: any) {
    return this.http.post(environment.baseURL + 'api/auth/login', body, options).toPromise()
      .then((response: any) => {
        return response
      })
      .catch(this.handleError);
  }

  changePassword(type: string, body: any) {
    return this.http
      .put<any>(
        environment.baseURL + `api/user/updateProfile/${type}`, body)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: Error) => {
          return throwError(`Failed to update ${type}.`);
        })
      );
  }

  logout() {
    this.isLogin = false;
    this.roleAs = '';
    localStorage.setItem('STATE', 'false');
    localStorage.setItem('ROLE', '');
  }

  isLoggedIn() {
    const loggedIn = localStorage.getItem('STATE');
    if (loggedIn == 'true')
      this.isLogin = true;
    else
      this.isLogin = false;
    return this.isLogin;
  }

  getRole() {
    this.roleAs = localStorage.getItem('ROLE');
    return this.roleAs;
  }

}
